<template>
  <el-card class="detail-top">
    <div class="top-card">
      <div class="card-img">
        <img v-if="headerData.img" :src="headerData.img" alt="" />
        <img class="kong_img_class" v-else :src="computedDetailImage" alt="" />
      </div>
      <div class="card-list">
        <div>
          <h3>{{ headerData.title }}</h3>
          <div class="avatar-share">
            <!-- <div class="avatar">
              <el-avatar
                style="width: 30px; height: 30px"
                size="small"
                :src="headerData.avatar"
              ></el-avatar>
              <span class="name">{{ headerData.nick_name }}</span>
            </div> -->
            <div class="share">
              <ShareBtn
                style="z-index: 99999"
                :shareData="headerData"
                :shareType="
                  $route.query.type == 1
                    ? '5'
                    : $route.query.type == 2
                    ? '4'
                    : $route.query.type == 3
                    ? '3'
                    : $route.query.type == 4
                    ? '2'
                    : $route.query.type
                "
              />
            </div>
          </div>
          <ul class="p-0 m-0">
            <li><i class="ri-calendar-event-line"></i>{{ headerData.time }}</li>
            <li>
              <i class="ri-t-box-line"></i>{{ $t("Events.0a7@language") }}：{{
                computedLang
              }}
            </li>
            <li>
              <i class="ri-align-justify"></i
              >{{ $t("Events.f04@organization") }}：
              {{ headerData.organization_name }}
            </li>
            <div class="pcIcon">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  style="margin-right: 12px; color: #333"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm.5 3a3.5 3.5 0 0 1 1.82 6.49L16.868 17h-2.472l-2.18-3H10v3H8V7h4.5zm0 2H10v3h2.5a1.5 1.5 0 0 0 1.493-1.356L14 10.5A1.5 1.5 0 0 0 12.5 9z"
                  /></svg
                >{{ $t("Events.37e@registration_peroid") }}：
                {{ headerData.time2 }}
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  class="mr-2"
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.536 4 7.332 5.114 5.865 6.865L8 9H2V3l2.447 2.446C6.28 3.336 8.984 2 12 2zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                  /></svg
                >{{ headerData.time_zone }}
              </li>
            </div>
            <div class="phoneIcon">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style="margin-right: 6px"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm.5 3a3.5 3.5 0 0 1 1.82 6.49L16.868 17h-2.472l-2.18-3H10v3H8V7h4.5zm0 2H10v3h2.5a1.5 1.5 0 0 0 1.493-1.356L14 10.5A1.5 1.5 0 0 0 12.5 9z"
                  /></svg
                >{{ $t("Events.37e@registration_peroid") }}：
                {{ headerData.time2 }}
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style="margin-right: 6px"
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.536 4 7.332 5.114 5.865 6.865L8 9H2V3l2.447 2.446C6.28 3.336 8.984 2 12 2zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                  /></svg
                >{{ headerData.time_zone }}
              </li>
            </div>
            <li v-if="headerData.is_interest_club == 1" style="padding: 0">
              <i class="lab la-rocketchat" style="padding: 0"></i>
              Interest Club：
              <a :href="headerData.interest_club_url" target="_blank">
                Click here
              </a>
            </li>

            <!-- 价格 -->
            <div
              v-if="headerData.package_type == 1"
              class="d-flex align-items-center"
              style="margin-bottom: 10px"
            >
              <section
                class="d-flex align-items-center"
                style="margin-right: 15px"
              >
                <div v-if="project_single.is_other_price"></div>
              </section>
              <section>
                <div class="single_price d-flex justify-content-between">
                  <section>
                    <div class="price" v-show="birldTime == 2">
                      <span style="color: #ef5959"
                        >{{ project_single.is_other_price ? "€" : "$"
                        }}{{ project_single.bird_price }}</span
                      >
                      <span style="color: #999; text-decoration: line-through"
                        >{{ project_single.is_other_price ? "€" : "$"
                        }}{{ project_single.price }}</span
                      >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`Birld Time: ${project_single.bird_time} `"
                        placement="top-start"
                      >
                        <i
                          class="ri-information-line"
                          style="
                            font-size: 20px;
                            color: #50b5ff;
                            text-decoration: none;
                          "
                        ></i>
                      </el-tooltip>
                    </div>
                    <div class="price" v-show="birldTime == 3">
                      <span style="color: #333"
                        >{{ project_single.is_other_price ? "€" : "$"
                        }}{{ project_single.price }}</span
                      >
                      <span></span>
                    </div>
                    <!-- <div class="price" v-show="birldTime == 1">
                      <span style="color: #333"
                        >{{ project_single.is_other_price ? "€" : "$"
                        }}{{ project_single.price }}</span
                      ></div> -->
                  </section>
                  <el-input-number
                    v-if="$route.query.IsShowCheckout!=0"
                    style="width:110px; "
                    v-model="num"
                    @change="handleChange"
                    :min="1"
                  ></el-input-number>
                </div>
              </section>
            </div>
          </ul>
        </div>
        <!-- 套餐按钮 -->
        <div v-if="headerData.package_type == 2" class="button-group">
          <el-button @click="checkPackage" v-if="$route.query.IsShowCheckout!=0" class="m-0" type="warning"
            >Check Out</el-button
          >
          <el-button v-if="headerData.package_is_buy" @click="goSchedule()" type="primary"
            >My Program</el-button
          >
        </div>
        <!-- 单一行程按钮 -->
        <div
          v-if="
            headerData.package_type == 1 && headerData.is_allow_register == 1
          "
          class="single_btn"
        >
          <el-button
            v-if="$route.query.IsShowCheckout!=0"
            type="warning"
            @click="checkSingle()"
            >{{ buttonText }}</el-button
          >

          <el-button
            type="primary"
            class=""
            v-if="
              headerData.is_register &&
                project_single.is_buy &&
                !project_single.is_attend
            "
            @click="singleAddToMyProgram(project_single)"
          >
            Add to my Schedule</el-button
          >
          <el-button
            style="background: #ccc; color: #fff"
            class=""
            v-if="
              headerData.is_register &&
                project_single.is_buy &&
                project_single.is_attend
            "
            @click="modelDeleteProgram(project_single)"
          >
            Cancel Schedule</el-button
          >
        </div>
        <div
          v-if="
            headerData.package_type == 1 && headerData.is_allow_register != 1
          "
        >
          <el-button
            type="primary"
            class=""
            v-if="project_single.is_buy && !project_single.is_attend"
            @click="singleAddToMyProgram(project_single)"
          >
            Add to my Schedule</el-button
          >
          <el-button
            type="primary"
            class=""
            v-if="project_single.is_buy && project_single.is_attend"
            @click="modelDeleteProgram(project_single)"
          >
            Check Schedule</el-button
          >
        </div>
      </div>
    </div>

    <b-modal id="payConfirm" title="Activity rules" hide-footer>
      <div>{{ refund_intro }}</div>
      <div
        style="border-top: 1px solid #eee; margin-top: 20px; padding-top: 10px"
      >
        <el-button
          style="float: right; margin-left: 10px"
          type="primary"
          @click="paySingle(payProgram)"
        >
          <section v-if="birldTime == 2">
            {{ project_single.bird_price == "0.00" ? "Confirm" : "Check out" }}
          </section>
          <section v-else>
            {{ project_single.price == "0.00" ? "Confirm" : "Check out" }}
          </section>
        </el-button>
        <el-button
          style="float: right"
          type="info"
          @click="$bvModal.hide('payConfirm')"
          >{{ $t("Events.8f0@cancel") }}
        </el-button>
      </div>
    </b-modal>
  </el-card>
</template>

<script>
import { mapState } from "vuex";
import { _throttle } from "@/Utils/throttle";
import ShareBtn from "@/components/ComComponents/ShareBtn.vue";
import moment from "moment";
export default {
  data() {
    return {
      autoTime: 0,
      timeId: 0,
      ShowBirldType: 0,
      birldTimeObj: {},
      birldTime: 0,
      activeLi: 1,
      selected: "",
      refund_intro: "",

      // 收款人id
      payee_id: 0,

      tips: false,

      buttonText: "Check Out",
      price_unit: 0,
      num: 1
    };
  },

  components: { ShareBtn },
  props: {
    register: {
      type: Function
    },
    type: {
      type: Number
    }
  },

  created() {
    this._getAutoTime();
    this.modelType = this.$route.query.type;
  },
  watch: {
    "$store.state.Process.project_single": {
      handler(val) {
        console.log("birldTime", val);
        if (!val) return;
        this.birldTimeObj = this.$store.state.Process.project_single;
        let birldTimeStart = this.birldTimeObj.bird_start_time;
        let birldTimeEnd = this.birldTimeObj.bird_end_time;
        console.log(birldTimeStart, birldTimeStart, 123);
        var date = new Date();
        var now = date.getTime();

        var start = new Date(birldTimeStart).getTime();
        var end = new Date(birldTimeEnd).getTime();

        let isBirldStart = now - start;
        let isBirldEnd = now - end;
        let modelText = { 2: "Event", 4: "Meeting" }[this.modelType];

        if (isBirldStart < 0) {
          // 说明早鸟时间还没开始，显示时间段，2
          this.birldTime = 1;
          if (Number(this.birldTimeObj.price) == 0) {
            this.buttonText = `Attend ${modelText}`;
          } else {
            this.buttonText = "Check Out";
          }
          // return 1
        } else if (isBirldStart > 0 && isBirldEnd < 0) {
          // 早鸟时间已经开始,倒计时是距离结束时间的倒计时
          this.birldTime = 2;
          if (Number(this.birldTimeObj.bird_price) == 0) {
            this.buttonText = `Attend ${modelText}`;
          } else {
            this.buttonText = "Check Out";
          }
        } else {
          // 早鸟时间已经结束或者不存在早鸟时间
          this.birldTime = 3;
          if (Number(this.birldTimeObj.price) == 0) {
            this.buttonText = `Attend ${modelText}`;
          } else {
            this.buttonText = "Check Out";
          }
          // return 3
        }
      },

      deep: true
    }
  },

  computed: {
    ...mapState({
      headerData: state => {
        if (state.Process.headerData) {
          return state.Process.headerData;
        }
      },
      introData: state => {
        if (state.Process.introData) {
          return state.Process.introData;
        }
      },
      project_single: state => state.Process.project_single
    }),
    computedDetailImage() {
      let url = this.headerData.img;
      return url ? url : require("@/assets/images/public/kong_img.png");
    },

    computedType() {
      let type = this.$route.query.type;
      if (type == 1) return "Project";
      if (type == 2) return "Event";
      if (type == 3) return "Course";
      if (type == 4) return "Meeting";
    },

    computedLang() {
      if (this.headerData.language) {
        return this.headerData.language.join("/");
      }
    },

    computedDetailImage() {
      let url = this.headerData.img;
      return url ? url : require("@/assets/images/public/kong_img.png");
    }
  },

  methods: {
    handleChange(value) {
      console.log(value);
    },
    goSchedule(){
      this.$router.push(`/myEventSchedule?id=${this.$route.query.id}&type=${this.$route.query.type}`)
    },
    handlerCommand(val) {
      this.price_unit = val;
    },
    checkSingle() {
      if (this.headerData.is_register) {
        this.payConf(this.birldTimeObj);
      } else {
        this.projectAttend();
      }
    },
    async projectAttend() {
      let type = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id"
      };
      let ajaxType = {
        1: "projectAttend",
        2: "eventAttend",
        3: "courseAttend",
        4: "meetingAttend"
      };
      let data;
      if (type == 2) {
        data = {
          [typeId[type]]: this.$route.query.id
        };
      } else {
        data = {
          [typeId[type]]: this.$route.query.id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
      }
      let res = await this.$http[ajaxType[type]](
        data
        // [typeId[type]]: this.$route.query.id,
        // time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      if (res.status == 200) {
        this.$bus.$emit(`update${this.computedType}Detail`);
        this.payConf(this.birldTimeObj);
      }
    },
    checkPackage() {
      let type = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id"
      };
      let ajaxType = {
        1: "projectAttend",
        2: "eventAttend",
        3: "courseAttend",
        4: "meetingAttend"
      };
      this.$http[ajaxType[type]]({ [typeId[type]]: this.$route.query.id }).then(res => {
        if (res.status == 200) {
          if(type==2){
            this.$router.push({
              path: "/EventProgram",
              query: { id: this.$route.query.id, type: this.$route.query.type }
            });
          }else{
            this.$router.push({
              path: "/MeetingProgram",
              query: { id: this.$route.query.id, type: this.$route.query.type }
            });
          }
          
        }
      });
    },
    payConf(data) {
      this.$bus.$emit("payConf", data);
    },
    getEventDetail() {
      this.$http.eventDetail({ event_id: this.$route.query.id }).then(res => {
        if (res.status == 200) {
          this.eventDetail = res.data;
          this.eventDetail.event_start_time = this.formatTime(
            this.eventDetail.event_start_time
          );
          this.eventDetail.event_end_time = this.formatTime(
            this.eventDetail.event_end_time
          );
        }
      });
    },
    limitInput(value, item) {
      if (/[^0-9]/g.test(value)) {
        this.$message.warning("Only numbers can be entered");
      }
      this.selected = value.replace(/[^0-9]/g, "");
    },
    openRewadModal() {
      this.$http
        .getTipPayee({
          model_type: this.headerData.tip_type,
          model_id: this.headerData.id
        })
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            this.payee_id = res.data.payee_id;
            // this.$bvModal.show("tips");
            this.tips = true;
          }
        });
    },
    rewardFunc() {
      this.$http
        .getUserTips({
          model_type: this.headerData.tip_type,
          model_id: this.headerData.id,
          diamond: this.selected,
          payee_id: this.payee_id
        })
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            this.tips = false;
            this.$bus.$emit(`update${this.computedType}Detail`);
            this.selected = "";
            this.$message.success(res.message);
          }
        });
    },
    isPlanToBuy() {
      let { ticket_sale_start, ticket_sale_end } = this.headerData;
      let start = Number(ticket_sale_start);
      let end = Number(ticket_sale_end);
      let now = new Date(
        moment(new Date()).format("YYYY/MM/DD HH:mm:ss")
      ).getTime();
      console.log(start, end, now);
      if (start > now) {
        this.$message.warning(this.$t("Events.235@event_has_not_start"));
        return;
      }
      if (now > end) {
        this.$message.warning(this.$t("Events.798@event_had_ended"));
        return;
      }
      if (now > start && now < end) {
        this.$router.push({
          path: "/PlanToBuy",
          query: {
            id: this.$route.query.id,
            type: this.$route.query.type
          }
        });
      }
    },
    payConf(program) {
      this.$bvModal.show("payConfirm");
      this.payProgram = program;
      let popupText;
      let modelText = { 2: "event", 4: "meeting" }[this.modelType];
      let { is_refund, refund_instruction } = program;
      // this.refund_intro = is_refund
      //   ? refund_instruction
      //   : 'Are you sure you want to attend the event?';
      // this.refund_intro = is_refund
      //   ? refund_instruction
      //   : this.$t("Events.cec@please_be_reminded_t");
      if (this.birldTime == 2) {
        popupText =
          this.project_single.bird_price == "0.00"
            ? `Are you sure you want to attend the ${modelText}?`
            : this.$t("Events.cec@please_be_reminded_t");
      } else {
        popupText =
          this.project_single.price == "0.00"
            ? `Are you sure you want to attend the ${modelText}?`
            : this.$t("Events.cec@please_be_reminded_t");
      }
      this.refund_intro = is_refund ? refund_instruction : popupText;
    },
    // 剩余时间
    _autoProcessTime() {
      if (this.headerData.package_type == 1) {
        var date = new Date();
        var now = date.getTime();
        var end = new Date(
          this.introData.project_single.bird_end_time
        ).getTime();
        var leftTime = end - now;
        var d, h, m, s;
        if (leftTime >= 0) {
          d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          // h = Math.floor(leftTime / 1000 / 60 / 60 % 24) + (d * 24);
          h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
          m = Math.floor((leftTime / 1000 / 60) % 60);
          s = Math.floor((leftTime / 1000) % 60);
        } else {
          d = h = m = s = "00";
        }
        this.autoTime = `${String(d).padStart(2, 0)}Day  ${String(h).padStart(
          2,
          0
        )}:${String(m).padStart(2, 0)}:${String(s).padStart(2, 0)}`;
      }
    },

    // 倒计时
    _getAutoTime() {
      clearInterval(this.timeId);
      this.timeId = setInterval(() => {
        this._autoProcessTime();
      }, 1000);
    },

    // 支付单品
    paySingle() {
      let type = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id"
      };
      let ajaxType = {
        1: "payPackage",
        2: "eventPayPackage",
        3: "coursePayPackage",
        4: "meetingPayPackage"
      };
      let buyProgram = {
        package_type: this.payProgram.package_type,
        model_type: this.payProgram.model_type,
        model_id: this.payProgram.model_id,
        program_id: this.payProgram.program_id
      };
      let ProgramArray = [];
      ProgramArray.push(buyProgram);
      let data;
      if (type == 2||type==4) {
        data = {
          [typeId[type]]: this.$route.query.id,
          order_type: 0, // 单品
          program_id: this.payProgram.program_id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
          price_unit: this.project_single.is_other_price,
          number: this.num
        };
      } else {
        data = {
          [typeId[type]]: this.$route.query.id,
          order_type: 0, // 单品
          program: ProgramArray,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
          [type == 2 ? "price_unit" :type == 4 ? "price_unit" : ""]: this.project_single.is_other_price
        };
      }
      this.$http[ajaxType[type]](data).then(res => {
        if (res.status == 200) {
          if (res.data.is_jump == 1) {
            this.$router.push({
              path: `/PayMoney`,
              query: {
                model_type: type,
                id: type == 2 ? res.data.id : type == 4 ? res.data.id : res.data.order_id,
                model_id: this.$route.query.id,
                package_type: 1
              }
            });
          } else if (res.data.is_jump == 0) {
            this.$router.push({
              path: `/PaySuccess`,
              query: {
                model_type: type,
                // typeStatus: 1,
                model_id: this.$route.query.id,
                package_type: 1
              }
            });
            this.$bus.$emit(`update${this.computedType}Detail`);
          }

          this.$bvModal.hide("payConfirm");
        }
      });
    },
    // 添加到我的行程
    singleAddToMyProgram: _throttle(function(v) {
      let type = this.$route.query.type;
      let ajaxType = {
        1: "projectAddProgram",
        2: "eventAddProgram",
        3: "courseAddProgram",
        4: "meetingAddProgram"
      };
      this.$http[ajaxType[type]]({
        my_program_id: v.my_program_id,
        my_program_model_type: v.my_program_model_type
      }).then(res => {
        if (res.status == 200) {
          this.$bus.$emit(`update${this.computedType}Detail`);
        }
      });
    }, 2000),
    // 取消行程
    modelDeleteProgram: _throttle(function(v) {
      let type = this.$route.query.type;
      let ajaxType = {
        1: "projectDeleteProgram",
        2: "eventDeleteProgram",
        3: "courseDeleteProgram",
        4: "meetingDeleteProgram"
      };
      this.$http[ajaxType[type]]({
        my_schedule_id: v.my_schedule_id,
        my_schedule_model_type: v.my_schedule_model_type
      }).then(res => {
        if (res.status == 200) {
          this.$bus.$emit(`update${this.computedType}Detail`);
        }
      });
    }, 2000)
  },

  beforeDestroy() {
    this.$bus.$off(`update${this.computedType}Detail`);
  }
};
</script>
<style lang="scss">
#tips {
  ::v-deep .modal-dialog.modal-md {
    max-width: 700px !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner {
  padding-left: 20px;
  padding-right: 20px;
}
::v-deep .el-input-number__decrease {
  width: 35px;
}
@media (min-width: 576px) {
  ::v-deep .el-dialog {
    width: 700px;
  }
  .detail-top {
    .top-card {
      display: flex;

      .card-img {
        flex: 1;
        height: 270px;
        border-radius: 5px;
        background: #eff0f2;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          overflow: hidden;

          &.kong_img_class {
            width: 39px;
            height: 30px;
            margin: 25% auto 0;
            object-fit: cover;
          }
        }
      }

      .card-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 24px;

        div:first-child {
          h3 {
            color: #333;
            font-size: 20px;
          }

          .avatar-share {
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            > div.avatar {
              display: flex;
              align-items: center;
              padding-right: 20px;
              border-right: 1px solid #f1f1f1;

              > span.name {
                padding-left: 12px;
                color: #333;
              }
            }

            div.share {
              // padding-left: 20px;
              color: #50b5ff;

              > i {
                padding-right: 6px;
              }
            }
          }

          > ul {
            .phoneIcon {
              display: none;
            }
            .pcIcon {
              display: block;
            }
            li {
              padding: 7.5px 0;
              line-height: 1;
              color: #333;

              > i {
                padding-right: 12px;
                color: #333;
                font-size: 16px;
              }

              > span {
                color: #666;
              }
            }
          }
        }

        .button-group {
          margin-top: 30px;
          display: flex;
          justify-content: speace-between;
          .el-button {
            max-width: 310px;
            width: 100%;
            height: 60px;
            border-radius: 5px;
            font-size: 18px;
          }
        }

        .single_btn {
          display: flex;
          justify-content: speace-between;
          .el-button {
            width: 100%;
            max-width: 310px;
            height: 60px;
            border-radius: 5px;
            font-size: 18px;
          }
        }
      }

      .event_select {
        background: #f5fbff;
        padding: 1px 15px 0.5px;
        border-radius: 5px;
      }
    }

    .top-list {
      display: flex;
      margin-top: 30px;

      > li,
      div {
        flex: 1;
        display: flex;
        justify-content: center;
        background-color: #f5fbff;
        margin-right: 10px;
        border-radius: 5px;
        padding: 20px 10px;
        color: #50b5ff;
        font-size: 16px;
        line-height: 27px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > span {
          padding-left: 10px;
        }

        > i {
          font-size: 27px;
        }
      }
    }

    // .early-birld {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   padding: 15px 10px;
    //   background: #f8f8f8;
    //   margin-bottom: 10px;

    //   > div
    .price {
      > span {
        &:first-child {
          font-size: 24px;
          font-weight: 500;
        }

        &:nth-child(2) {
          font-size: 14px;
          padding-left: 10px;
          padding-right: 20px;
        }

        &:last-child {
          font-size: 15px;
        }

        > i {
          color: #ef5959;
          font-style: normal;
        }
      }
    }

    // }

    .el-button--warning.is-plain:focus,
    .el-button--warning.is-plain:hover {
      background: #fdf6ec;
      border: #f5dab1;
      color: #e6a23c;
    }
  }

  .self_pre_input {
    > span {
      display: block;
      width: 122px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: #f8f8f8;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    input.el-input__inner {
      border-color: none;
    }
  }

  .single_price {
    .price{
      min-width: 150px;
    }
    > span {
      &:first-child {
        font-size: 24px;
        font-weight: 500;
      }

      &:nth-child(2) {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .outlineBlue {
    color: #50b5ff;
    border: 1px solid #50b5ff !important;
    background-color: #f5fbff;
  }

  .btn-box {
    display: flex;
    margin-left: 30px;

    .btn {
      width: 130px;
    }
  }

  .btn-select {
    display: flex;
    margin-top: 26px;

    .self-btn {
      width: 100%;
      height: 45px;
      border: 1px solid #f1f1f1;
      border-radius: 10px;
      text-align: center;
      line-height: 45px;
      position: relative;
      margin: 5px 0;
      margin-right: 20px;

      > input {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .common-part {
    padding: 20px;

    > h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }

    > .btn-select {
      display: flex;
      margin-top: 26px;

      .outlineBule {
        border-color: #50b5ff;
        background-color: #50b5ff;
      }

      > li {
        width: 315px;
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        position: relative;
        cursor: pointer;

        &.self-r {
          margin-right: 20px;
        }

        > input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .choosed-result {
      padding-top: 30px;
    }
  }
}

@media (max-width: 576px) {
  ::v-deep .el-card__body {
    padding: 10px;
  }
  ::v-deep .el-dialog {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin-top: 15rem;
  }
  .detail-top {
    width: 92%;
    margin: 0 auto;
    .top-card {
      // display: flex;

      .card-img {
        width: 100%;
        height: 190px;
        border-radius: 5px;
        background: #eff0f2;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          overflow: hidden;

          &.kong_img_class {
            width: 39px;
            height: 30px;
            margin: 25% auto 0;
            object-fit: cover;
          }
        }
      }

      .card-list {
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // padding-left: 24px;

        div:first-child {
          h3 {
            color: #333;
            font-size: 14px;
            font-weight: 500;
          }

          .avatar-share {
            display: flex;
            font-size: 12px;
            align-items: center;

            > div.avatar {
              display: flex;
              align-items: center;
              padding-right: 20px;
              border-right: 1px solid #f1f1f1;

              > span.name {
                padding-left: 12px;
                color: #333;
              }
            }

            div.share {
              // padding-left: 20px;
              color: #50b5ff;

              > i {
                padding-right: 6px;
              }
            }
          }

          > ul {
            .phoneIcon {
              display: block;
            }
            .pcIcon {
              display: none;
            }
            li {
              padding: 5px 0;
              line-height: 1;
              color: #333;

              > i {
                padding-right: 6px;
                color: #333;
                font-size: 14px;
              }

              > span {
                color: #666;
              }
            }
          }
        }
        ul {
          font-size: 12px;
        }
        .button-group {
          margin-top: 20px;

          .el-button {
            height: 30px;
            width: 155px;
            border-radius: 5px;
            font-size: 10px;
            padding: 0;
          }
        }

        .single_btn {
          .el-button {
            height: 30px;
            width: 155px;
            border-radius: 5px;
            font-size: 10px;
            padding: 0;
          }
        }
      }

      .event_select {
        background: #f5fbff;
        padding: 1px 15px 0.5px;
        border-radius: 5px;
      }
    }

    .top-list {
      display: flex;
      margin-top: 30px;

      > li,
      div {
        flex: 1;
        display: flex;
        justify-content: center;
        background-color: #f5fbff;
        margin-right: 10px;
        border-radius: 5px;
        padding: 20px 10px;
        color: #50b5ff;
        font-size: 12px;
        line-height: 27px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > span {
          padding-left: 10px;
        }

        > i {
          font-size: 16px;
        }
      }
    }

    // .early-birld {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   padding: 15px 10px;
    //   background: #f8f8f8;
    //   margin-bottom: 10px;

    //   > div
    .price {
      > span {
        &:first-child {
          font-size: 12px;
          font-weight: 500;
        }

        &:nth-child(2) {
          font-size: 10px;
          padding-left: 10px;
          padding-right: 20px;
        }

        &:last-child {
          font-size: 8px;
        }

        > i {
          color: #ef5959;
          font-style: normal;
        }
      }
    }

    // }

    .el-button--warning.is-plain:focus,
    .el-button--warning.is-plain:hover {
      background: #fdf6ec;
      border: #f5dab1;
      color: #e6a23c;
    }
  }
  .common-part {
    padding: 20px;

    > h3 {
      font-size: 10px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }

    > .btn-select {
      margin-top: 26px;

      > li {
        width: 100%;
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        position: relative;
        margin: 5px 0;

        &.self-r {
          margin-right: 20px;
        }

        > input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    > .three-btn {
      display: flex;
      flex-wrap: wrap;

      > li {
        width: calc(33.3333% - 20px);
      }
    }

    .choosed-result {
      padding-top: 30px;
    }
  }
}

.textYellow {
  text-align: right;
  color: #ffba68;
  line-height: 26px;
  cursor: pointer;
}

.currentBalance {
  font-family: montserrat;
  color: #999999;

  span {
    color: #50b5ff;
  }
}
</style>
